// modeloProposta.js
import React, { useState, useEffect } from "react";
import "./ModeloProposta.css";
import logoSvg from "../../assets/logo.svg";

function ModeloProposta({
  personTitle,
  personSubtitle,
  personInfo,
  personLanguage,
  personCity,
  personImage,
  personState,
  personThemes,
  serviceType,
  serviceName,
  serviceCNPJ,
  personValour,
  serviceTime,
  paymentWays,
  contactMail,
  titleDoc,
}) {
  function dividirPorPalavras(conteudo) {
    // Remove espaços adicionais no final do conteúdo
    conteudo = conteudo.trim();

    const palavras = conteudo.split(" ");
    const totalPalavras = palavras.length;

    if (totalPalavras % 2 === 0) {
      // Se o número de palavras for par, divide igualmente
      const metade = totalPalavras / 2;
      const parte1 = palavras.slice(0, metade).join(" ");
      const parte2 = palavras.slice(metade).join(" ");
      return [parte1, parte2];
    } else {
      // Se o número de palavras for ímpar, uma parte terá uma palavra a mais
      const metade = Math.floor(totalPalavras / 2);
      const parte1 = palavras.slice(0, metade + 1).join(" ");
      const parte2 = palavras.slice(metade + 1).join(" ");
      return [parte1, parte2];
    }
  }

  const personName = personTitle.split(" ")[0];

  const [personSubtitlePt1, personSubtitlePt2] = dividirPorPalavras(personSubtitle);
  const [serviceTypePt1, serviceTypePt2] = dividirPorPalavras(serviceType);
  const [serviceNamePt1, serviceNamePt2] = dividirPorPalavras(serviceName);

  function ajustarTamanhoFonte(containerID, contentID) {
    const container = document.getElementById(containerID);
    const content = document.getElementById(contentID);

    content.style.fontSize = "35px";

    while (content.offsetWidth > container.offsetWidth && parseFloat(window.getComputedStyle(content).fontSize) > 12) {
      content.style.fontSize = `${parseFloat(window.getComputedStyle(content).fontSize) - 1}px`;
    }
  }

  useEffect(() => {
    const clientContainer = document.getElementById("clientContainer");
    if (!serviceName & !serviceCNPJ) {
      clientContainer.style.display = "none";
    } else {
      clientContainer.style.display = "block";
    }
    var dataActual = new Date();
    var printOptions = { year: "numeric", month: "long", day: "numeric" };
    var formatData = new Intl.DateTimeFormat("pt-BR", printOptions);
    var dataFormat = formatData.format(dataActual);

    document.getElementById("dataActual").innerHTML = "São Paulo, " + dataFormat;
    //
    //
    ajustarTamanhoFonte("titleContainer", "title");
    ajustarTamanhoFonte("serviceContainer", "serviceType");
    ajustarTamanhoFonte("serviceManContainer", "serviceMan");
    console.log(paymentWays);
    if (personThemes) {
      const container = document.getElementById("themeList");
      container.innerHTML = "";

      const newArray = personThemes.split(",");
      const trimmedArray = newArray.map((theme) => theme.trim());

      // Limitar o array a no máximo 6 valores
      const limitedArray = trimmedArray.slice(0, 6);

      limitedArray.forEach((tema, index) => {
        const li = document.createElement("li");
        li.className = "themes__line text text--medium";

        // Adicionar a classe específica para elementos a partir do ID 4
        if (index >= 3) {
          li.classList.add("text--medium-bold");
        }

        // Verificar se tema é um array antes de processar
        if (Array.isArray(tema)) {
          li.textContent = tema.join(", "); // Transforma o array em uma string com palavras separadas por vírgula e espaço
        } else {
          li.textContent = tema;
        }

        li.id = `theme${index + 1}`;
        container.appendChild(li);
      });
    }
  }, [personThemes, personLanguage, personTitle, serviceType, serviceName, paymentWays, serviceCNPJ]);
  return (
    <div class="body">
      <div id="page1" class="body__content">
        {/* <!-- ================== --> */}
        {/* <!-- Block: Header --> */}
        <header class="header">
          <section class="header__content header__content--primary">
            <div class="header__container">
              <img class="logo" alt="logo" src={logoSvg} />
              <p id="titleModel" class="text text--light">
                {titleDoc}
              </p>
            </div>
            <div class="header__container">
              <p id="contactEmail" class="text text--light">
                {contactMail}
              </p>
            </div>
          </section>
          <span class="header__background"></span>
        </header>
        {/* <!-- ================== --> */}
        {/* <!-- Block: Main --> */}
        <main class="main main--proposta">
          <div class="main__content main__content--primary">
            <div class="main__infotext">
              {/* <!-- ================== --> */}
              {/* <!-- Element: Aside --> */}
              <aside class="main__aside">
                <div class="aside__picture">
                  <span class="aside__rectangle"></span>
                  <img id="image" class="aside__image" alt="Imagem" src={personImage} />
                  <span class="aside__square"></span>
                </div>
                {/* <!--  --> */}
                <section class="aside__info">
                  <div class="info__container">
                    <p class="info__title text text--large">Idiomas:</p>
                    <ul id="languageList" class="info__list">
                      <li id="language1" class="text text--medium">
                        {personLanguage}
                      </li>
                    </ul>
                  </div>

                  <div class="info__container">
                    <p class="info__title text--large">Cidade/Origem:</p>
                    <ul class="info__list">
                      <li class="text text--medium">
                        <span id="city"> {personCity}</span>
                        {", "}
                        <span id="state">{personState}</span>
                      </li>
                    </ul>
                  </div>
                </section>
                {/* <!--  --> */}
                <div class="overlay overlay--right">
                  <div class="overlay__content">
                    <p id="titleOverlayOne" class="overlay__item overlay__item--up">
                      {personName}
                    </p>
                    <p id="titleOverlayTwo" class="overlay__item overlay__item--down">
                      {personName}
                    </p>
                  </div>
                </div>
              </aside>
              {/* <!-- ================== --> */}
              {/* <!-- Element: details --> */}
              <aside class="main__details">
                <div id="titleContainer" class="main__header">
                  <h1 id="title" class="main__title">
                    {personTitle}
                  </h1>
                  <p class="main__subtitle">
                    <span id="subtitleOne" class="text text--medium">
                      {personSubtitlePt1}{" "}
                    </span>
                    <span id="subtitleTwo" class="text text--medium text--medium-bold">
                      {personSubtitlePt2}
                    </span>
                  </p>
                  <span class="main__rectangle rectangle rectangle--dark"></span>
                </div>
                <span id="detailsText" class="main__text text text--regular">
                  {personInfo && personInfo.split("\n").map((paragrafo, index) => <p key={index}>{paragrafo}</p>)}
                </span>
              </aside>
            </div>
            {/* <!-- ================== --> */}
            {/* <!-- Element: Themes --> */}
            <section class="main__themes">
              <div class="themes__content">
                <h2 class="themes__title">Principais Temas:</h2>

                <div class="themes__rectangle">
                  <span class="rectangle rectangle--dark"></span>
                </div>

                <ul class="themes__list" id="themeList">
                  <li id="themeOne" class="themes__line text text--medium"></li>
                  <li id="themeTwo" class="themes__line text text--medium"></li>
                  <li id="themeThree" class="themes__line text text--medium"></li>
                  <li id="themeFour" class="themes__line text text--medium text--medium-bold"></li>
                  <li id="themeFive" class="themes__line text text--medium text--medium-bold"></li>
                  <li id="themeSix" class="themes__line text text--medium text--medium-bold"></li>
                </ul>
              </div>

              <div class="overlay overlay--left">
                <p class="overlay__item overlay__item--up"> {personName}</p>
                <p class="overlay__item overlay__item--down"> {personName}</p>
              </div>
            </section>
          </div>
        </main>
        {/* <!-- ================== --> */}
        {/* <!-- Block: Footer --> */}
        <footer class="footer">
          <span class="footer__background"></span>
        </footer>
        {/* <!--  --> */}
      </div>
      {/* <!-- ================== --> */}
      {/* <!-- ================== --> */}
      {/* <!-- ================== --> */}
      <div id="page2" class="body__content body__content--secondary">
        {/* <!-- Block: Header --> */}
        <div class="header">
          <section class="header__content header__content--secondary">
            <div class="header__container">
              <img class="logo" alt="logo" src={logoSvg} />
              <p id="titleModelTwo" class="text text--light">
                {titleDoc}
              </p>
            </div>
          </section>
        </div>
        {/* <!-- ================== --> */}
        {/* <!-- Block: Main --> */}
        <div class="main">
          <div class="main__content main__content--secondary">
            <div class="main__container">
              <div class="main__sub-container">
                <section class="main__item item__service">
                  <div id="serviceContainer" class="service__type">
                    <p class="text text--medium-bold">Serviço Solicitado:</p>
                    <p id="serviceType" class="main__title">
                      {serviceTypePt1} <span class="text--medium-bold">{serviceTypePt2}</span>
                    </p>
                    <span class="main__rectangle-secondary rectangle rectangle--white"></span>
                  </div>
                  {/* <!--  --> */}
                  <div class="service__contractor" id="serviceManContainer">
                    <p id="clientContainer" class="text text--medium-bold">
                      Solicitado por:
                    </p>
                    <p id="serviceMan" class="contractor__text">
                      <span id="serviceContractorFirst" class="text text--medium">
                        {serviceNamePt1}
                      </span>
                      <span id="serviceContractorSecond" class="text text--medium text--medium-bold">
                        {serviceNamePt2}
                      </span>
                    </p>
                    <p id="serviceContractorCNPJ" class="text--light service__text">
                      {serviceCNPJ}
                    </p>
                  </div>
                </section>
                {/* <!--  --> */}
                <section class="main__item">
                  <p class="text text--large">Investimento</p>
                  <p class="investment__text">
                    <span class="text text--medium text--medium-bold">VALOR PARA {serviceType}:</span>
                    <span class="text text--large"> {personValour} </span>
                  </p>
                  <p class="text text--regular">
                    Nessa opção, as despesas com passagens aéreas, traslados, alimentação e hospedagem (quando necessário), para o Profissional e
                    Assessoria, correm por conta do Contratante.
                  </p>
                </section>
              </div>
              {/* <!--  --> */}
              <section class="main__item item__payment">
                <p class="text text--large">
                  <span class="text text--large"> Outras informações </span>/<span> Formas de Pagamento </span>
                </p>
                <div class="payment__text text text--regular">
                  {paymentWays && paymentWays.split("\n").map((paragrafo, index) => <p key={index}>{paragrafo}</p>)}
                </div>
              </section>
              {/* <!--  --> */}
              <section class="main__item item__footer">
                <div>
                  <p class="footer__title text text--medium text--medium-bold">
                    Equipamentos necessários <br />
                    para a realização da palestra:
                  </p>
                  <p class="text text--regular">
                    Microfone de lapela ou de mão sem fio, notebook, projetor, telão e camarim <br />
                    (uma sala vazia para o palestrante se preparar antes do início da palestra)
                  </p>
                </div>
                {/* <!--  --> */}
                <div class="footer__service-time">
                  <p class="footer__title text text--medium text--medium-bold">Tempo médio de palestra:</p>
                  <p class="text text--regular"> {serviceTime}</p>
                </div>
              </section>
              {/* <!--  --> */}
              <div class="main__budget">
                <p class="text text--regular">Este orçamento tem validade de 7 dias úteis</p>
              </div>
              {/* <!--  --> */}
              <div class="main__item item__signature">
                <p id="dataActual" class="text text--medium text--medium-bold"></p>
                <span class="main__rectangle-secondary rectangle rectangle--white"></span>
              </div>
              {/* <!--  --> */}
              <div class="main__item item__mail">
                <p id="contactEmailTwo" class="text text--light">
                  {contactMail}
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <!--  --> */}
      </div>
    </div>
  );
}

export default ModeloProposta;
