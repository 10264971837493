// App.js

import React, { useState, useEffect } from "react";
import LoginPage from "./pages/LoginPage/LoginPage";
import CriarProposta from "./pages/CriarProposta/CriarProposta";
import { Router, Route, Switch } from "wouter";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [loggedInUser, setLoggedInUser] = useState(null);

  useEffect(() => {
    // Verifica se o usuário já está logado ao carregar a página
    const loggedIn = localStorage.getItem("isLoggedIn");
    if (loggedIn === "true") {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = () => {
    setIsLoggedIn(true);
    localStorage.setItem("isLoggedIn", "true");
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem("isLoggedIn");
  };

  return (
    <Router>
      <Switch>
        <Route path="/">
          <LoginPage onLogin={handleLogin} />
        </Route>
        {isLoggedIn && (
          <Route path="/criarproposta">
            <CriarProposta onLogout={handleLogout} />
          </Route>
        )}
      </Switch>
    </Router>
  );
};

export default App;
