// Header.js
import React, { useState, useEffect } from "react";
import "./Header.css";
import IconSvg from "../../assets/Icon.svg";
import IconChevronDown from "../../assets/icons/chevron-down.svg";
import IconLogout from "../../assets/icons/log-out.svg";
import { Router, useRoute } from "wouter";

const profileDefaultImg =
  "https://img.freepik.com/fotos-gratis/estilo-de-vida-beleza-e-moda-conceito-de-emocoes-de-pessoas-jovem-gerente-de-escritorio-feminino-asiatico-ceo-com-expressao-satisfeita-em-pe-sobre-um-fundo-branco-sorrindo-com-os-bracos-cruzados-sobre-o-peito_1258-59329.jpg";

function Header({ loggedInUser }) {
  const [menuVisible, setMenuVisible] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };
  const handleMouseEnter = () => {
    setMenuVisible(true);
  };

  const handleMouseLeave = () => {
    setMenuVisible(false);
  };

  const handleLogout = () => {
    localStorage.removeItem("isLoggedIn");
    window.location.href = "/";
  };

  return (
    <header className="header-item">
      <section className="header__container">
        <div className="header__sub-container header__status">
          <p>Status:</p>
          <p className="header__status-text">⬤ Disponivel</p>
        </div>
        <div className="header__sub-container">
          {/* <p>Olá,</p> */}
          <p id="header__username" className="header__username">
            {loggedInUser}
          </p>
          <section className="header__profile">
            <img className="header__profile-img" src={profileDefaultImg} />
            <div className="header__profile-menu ">
              <button className="btn-no" id="user-menu" onClick={toggleMenu}>
                <img src={IconChevronDown} alt="Menu" />
              </button>
              <div
                className={`header__menu ${menuVisible ? "header__menu--visible" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <span className="header__menu-area"></span>
                <button id="user-exit" className="header__menu-btn" onClick={handleLogout}>
                  <svg className="header__menu-icon" width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.375 15.375H3.54167C3.16594 15.375 2.80561 15.2257 2.53993 14.9601C2.27426 14.6944 2.125 14.3341 2.125 13.9583V4.04167C2.125 3.66594 2.27426 3.30561 2.53993 3.03993C2.80561 2.77426 3.16594 2.625 3.54167 2.625H6.375"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.3333 12.5416L14.8749 8.99998L11.3333 5.45831"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path d="M14.875 9H6.375" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                  <span> Sair</span>
                </button>
              </div>
              <span className={`header__overlay ${menuVisible ? "header__overlay--visible" : ""}`}></span>{" "}
            </div>
          </section>
        </div>
      </section>
      <img className="header__icon" src={IconSvg} />
    </header>
  );
}
export default Header;
