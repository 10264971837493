import React, { useState, useEffect } from "react";
import ModeloProposta from "../../components/ModeloProposta/ModeloProposta";
import Header from "../../components/Header/Header";
import { Router, useRoute } from "wouter";

import "./CriarProposta.css"; // Importe o arquivo de estilos se necessário

function CriarProposta() {
  const [personTitle, setPersonTitle] = useState("");
  const [personSubtitle, setPersonSubtitle] = useState("");
  const [personInfo, setPersonInfo] = useState("");
  const [personLanguage, setPersonLanguage] = useState("");
  const [personCity, setPersonCity] = useState("");
  const [personState, setPersonState] = useState("");
  const [personImage, setPersonImage] = useState(
    "https://img.freepik.com/fotos-gratis/estilo-de-vida-beleza-e-moda-conceito-de-emocoes-de-pessoas-jovem-gerente-de-escritorio-feminino-asiatico-ceo-com-expressao-satisfeita-em-pe-sobre-um-fundo-branco-sorrindo-com-os-bracos-cruzados-sobre-o-peito_1258-59329.jpg"
  );
  const [personThemes, setPersonThemes] = useState("");
  const [serviceType, setServiceType] = useState("SERVIÇO PRESENCIAL");
  const [serviceName, setServiceName] = useState("");
  const [serviceCNPJ, setServiceCNPJ] = useState("");
  const [personValour, setPersonValour] = useState("R$ 10.000,00");
  const [serviceTime, setServiceTime] = useState("60 minutos");
  const [paymentWays, setPaymentWays] = useState(
    "É importante que você saiba que o valor informado nesse orçamento é válido somente caso o pagamento seja efetuado por meio de 2 notas fiscais, uma do palestrante e uma da Conieh. Caso isso seja um problema, nos informe, pois também é possível viabilizar a emissão do pagamento por apenas 1 nota fiscal. No entanto, será incluso um valor devido aos impostos decorrentes dessa operação.\n\nOs palestrantes sempre pedem que o pagamento total seja concluído antes da realização da palestra. Existem 2 opções de pagamento:\n\n• 1. À vista\n• 2. Parcelado em 2x (50% na assinatura do contrato e 50% em até quinze dias antes do evento"
  );
  const [contactMail, setContactMail] = useState("contato@conieh.com.br");
  const [titleDoc, setTitleDoc] = useState("Proposta Comercial");

  //

  //
  const [canPrint, setCanPrint] = useState(false);

  const make = () => {
    setCanPrint(true);
  };

  useEffect(() => {
    if (canPrint) {
      const timeoutId = setTimeout(() => {
        var printOptions = {
          silent: false,
          printBackground: true,
          color: true,
          marginTop: 10,
          marginBottom: 10,
          marginLeft: 10,
          marginRight: 10,
          paperSize: {
            width: 210,
            height: 297,
            unit: "mm",
          },
        };

        window.print(printOptions);

        // Removendo a declaração da variável timeoutId2 se não for necessária
        setTimeout(() => {
          setCanPrint(false);
        }, 500);
      }, 500);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [canPrint]);

  const handlePersonTitleChange = (e) => {
    setPersonTitle(e.target.value);
  };
  const handlePersonSubtitle = (e) => {
    setPersonSubtitle(e.target.value);
  };
  const handlePersonInfo = (e) => {
    setPersonInfo(e.target.value);
  };
  const handlePersonLanguage = (e) => {
    setPersonLanguage(e.target.value);
  };
  const handlePersonCity = (e) => {
    setPersonCity(e.target.value);
  };
  const handlePersonState = (e) => {
    setPersonState(e.target.value);
  };
  const handlePersonThemes = (e) => {
    setPersonThemes(e.target.value);
  };
  const handleServiceType = (e) => {
    setServiceType(e.target.value);
  };
  const handleServiceName = (e) => {
    setServiceName(e.target.value);
  };
  const handleServiceCNPJ = (e) => {
    setServiceCNPJ(e.target.value);
  };
  const handlePersonValour = (e) => {
    setPersonValour(e.target.value);
  };
  const handleServiceTime = (e) => {
    setServiceTime(e.target.value);
  };
  const handlePersonImage = (e) => {
    setPersonImage(e.target.value);
  };
  const handlePaymentWays = (e) => {
    setPaymentWays(e.target.value);
  };
  const handleContactMail = (e) => {
    setContactMail(e.target.value);
  };
  const handleTitleDoc = (e) => {
    setTitleDoc(e.target.value);
  };

  return (
    <div className="body-page">
      {!canPrint ? (
        <div className="page-body">
          <Header />
          <div id="main" className="main">
            <div className="form">
              <section className="form__container">
                <details className="form__details">
                  <summary className="form__summary">Página 1</summary>
                  <div className="form__sub-container">
                    <div className="form__flex">
                      <section className="form__item">
                        <label className="label label--required" htmlFor="formNome">
                          Nome:
                        </label>
                        <input
                          id="formNome"
                          className="input"
                          type="text"
                          value={personTitle}
                          onChange={handlePersonTitleChange}
                          placeholder="Insira o nome aqui..."
                        />
                      </section>
                      <section className="form__item">
                        <label className="label">Titulo ou Realização:</label>
                        <input
                          className="input"
                          type="text"
                          value={personSubtitle}
                          onChange={handlePersonSubtitle}
                          placeholder="Ex: Campeão Olimpico"
                        />
                      </section>
                      <section className="form__item ">
                        <label className="label label--required">Imagem URL:</label>
                        <input className="input" type="text" value={personImage} onChange={handlePersonImage} />
                      </section>
                    </div>

                    <label className="label label--required">Informações:</label>
                    <textarea
                      className="input input--textarea"
                      type="text"
                      maxLength={4000}
                      value={personInfo}
                      onChange={handlePersonInfo}
                      placeholder="Insira as informações aqui..."
                    />

                    <section className="form__item">
                      <span>
                        <label className="label label--required">Principais Temas:</label>
                        <span className="form__observation">Ultilize virgulas para separar os temas.</span>
                      </span>
                      <input
                        className="input"
                        type="text"
                        value={personThemes}
                        onChange={handlePersonThemes}
                        placeholder="Ex: Tema 1, Tema 2, Tema 3, Tema 4, Tema 5, Tema 6"
                      />
                    </section>
                    <div className="form__flex">
                      <section className="form__item">
                        <label className="label label--required">Idiomas:</label>
                        <input
                          className="input"
                          type="text"
                          value={personLanguage}
                          onChange={handlePersonLanguage}
                          placeholder="Insira os idiomas aqui..."
                        />
                      </section>
                      <section className="form__item">
                        <label className="label label--required">Cidade:</label>
                        <input className="input" type="text" value={personCity} onChange={handlePersonCity} placeholder="Insira a cidade aqui..." />
                      </section>
                      <section className="form__item">
                        <label className="label label--required">Estado:</label>
                        <input className="input" type="text" value={personState} onChange={handlePersonState} placeholder="Insira o estado aqui..." />
                      </section>
                    </div>
                  </div>
                </details>
                <details className="form__details">
                  <summary className="form__summary">Página 2</summary>
                  <div className=" form__sub-container">
                    <div className="form__flex">
                      <section className="form__item">
                        <label className="label label--required">Tipo de Serviço:</label>
                        <input
                          className="input"
                          type="text"
                          value={serviceType}
                          onChange={handleServiceType}
                          placeholder="Insira o tipo do serviço aqui..."
                        />
                      </section>

                      <section className="form__item">
                        <label className="label label--required">Investimento:</label>
                        <input
                          className="input"
                          type="text"
                          value={personValour}
                          onChange={handlePersonValour}
                          placeholder="Insira o valor do investimento aqui..."
                        />
                      </section>
                      <section className="form__item">
                        <label className="label label--required">Tempo de Serviço:</label>
                        <input
                          className="input"
                          type="text"
                          value={serviceTime}
                          onChange={handleServiceTime}
                          placeholder="Insira o tempo do serviço aqui..."
                        />
                      </section>
                    </div>
                    <label className="label label--required">Formas de Pagamento:</label>
                    <textarea
                      className="input input--textarea"
                      type="text"
                      maxLength={1468}
                      value={paymentWays}
                      onChange={handlePaymentWays}
                      placeholder="Insira as informações aqui..."
                    />
                    <div className="form__flex">
                      <section className="form__item">
                        <label className="label">Cliente:</label>
                        <input
                          className="input"
                          type="text"
                          value={serviceName}
                          onChange={handleServiceName}
                          placeholder="Insira o nome do cliente aqui..."
                        />
                      </section>
                      <section className="form__item">
                        <label className="label">CNPJ Cliente:</label>
                        <input
                          className="input"
                          type="text"
                          value={serviceCNPJ}
                          onChange={handleServiceCNPJ}
                          placeholder="Insira o CNPJ do cliente aqui..."
                        />
                      </section>
                    </div>
                  </div>
                </details>
                <details className="form__details">
                  <summary className="form__summary">Informações Avançadas</summary>
                  <div className="form__flex">
                    <section className="form__item">
                      <label className="label label--required">E-mail de Contato:</label>
                      <input className="input" type="text" value={contactMail} onChange={handleContactMail} />
                    </section>
                    <section className="form__item">
                      <label className="label label--required">Titulo do Documento:</label>
                      <input className="input" type="text" value={titleDoc} onChange={handleTitleDoc} />
                    </section>
                  </div>
                </details>
              </section>
              <button class="button" onClick={make}>
                Gerar PDF
              </button>
            </div>
            <ModeloProposta
              personTitle={personTitle}
              personSubtitle={personSubtitle}
              personInfo={personInfo}
              personLanguage={personLanguage}
              personImage={personImage}
              personCity={personCity}
              personState={personState}
              personThemes={personThemes}
              serviceType={serviceType}
              serviceName={serviceName}
              serviceCNPJ={serviceCNPJ}
              personValour={personValour}
              serviceTime={serviceTime}
              paymentWays={paymentWays}
              contactMail={contactMail}
              titleDoc={titleDoc}
            />{" "}
          </div>
        </div>
      ) : (
        <ModeloProposta
          personTitle={personTitle}
          personSubtitle={personSubtitle}
          personInfo={personInfo}
          personLanguage={personLanguage}
          personImage={personImage}
          personCity={personCity}
          personState={personState}
          personThemes={personThemes}
          serviceType={serviceType}
          serviceName={serviceName}
          serviceCNPJ={serviceCNPJ}
          personValour={personValour}
          serviceTime={serviceTime}
          paymentWays={paymentWays}
          contactMail={contactMail}
          titleDoc={titleDoc}
        />
      )}
    </div>
  );
}

export default CriarProposta;
