import React, { useState } from "react";
import { useLocation } from "wouter";
import users from "./users";
import IconSvg from "../../assets/Icon.svg";
import "../../components/Header/Header.css";
import "./LoginPage.css";

const LoginPage = ({ onLogin, setLoggedInUser }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [, setLocation] = useLocation();

  const handleLogin = () => {
    const user = users.find((user) => user.username === username && user.password === password);
    if (user) {
      onLogin();
      setLocation("/criarproposta");
    } else {
      alert("Credenciais inválidas. Por favor, tente novamente.");
    }
  };

  return (
    <div className="login-background">
      <div className="login-page">
        <header className="login-header">
          <svg className="login-header__icon" alt="Icon" width="235" height="302" viewBox="0 0 235 302" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.6248 17.9246C72.3796 10.2413 88.5316 3.2976 101.148 1.36381C103.849 0.950261 111.992 0.7311 119.244 0.87747C130.257 1.09974 133.665 1.4645 139.93 3.09548C164.557 9.50479 183.208 24.5941 190.538 44.0396C192.257 48.598 194.09 58.3374 194.09 62.9074V64.7746L173.343 64.9814L152.595 65.1874L152.46 71.2792C152.386 74.6294 152.511 77.8534 152.737 78.4428C153.074 79.3179 156.904 79.5146 173.649 79.5146H194.148L193.926 86.5357L193.702 93.5576L173.434 93.4833C157.607 93.4244 153.093 93.6258 152.832 94.3995C152.256 96.1017 152.082 105.779 152.597 107.4C153.071 108.89 153.793 108.944 173.589 108.944H194.09L194.091 116.107V123.271L174.229 123.052C160.053 122.894 154.059 123.088 153.288 123.727C152.162 124.659 151.721 134.657 152.693 137.185C153.126 138.313 155.349 138.435 173.44 138.323L193.702 138.197L193.926 145.255L194.148 152.312H173.649C155.671 152.312 153.091 152.463 152.678 153.538C151.706 156.068 152.172 166.034 153.307 166.974C154.105 167.635 159.887 167.821 174.34 167.65L194.273 167.414L193.743 173.934C191.465 201.935 167.835 224.785 134.811 230.923C127.23 232.331 108.878 232.571 101.405 231.359C66.8813 225.759 41.6307 202.374 39.96 174.455L39.5381 167.414L58.4497 167.639C68.8514 167.763 78.1463 167.669 79.1065 167.43C80.7632 167.019 80.8516 166.625 80.8516 159.655V152.312H60.2685H39.6862L39.9089 145.322L40.1322 138.331L60.298 138.158L80.4638 137.985V130.628V123.271H60.1041H39.7436L39.7444 116.107V108.944H60.3259H80.9074L80.6856 101.393C80.5289 96.0521 80.1799 93.8132 79.4943 93.745C78.9614 93.6916 69.7992 93.6049 59.1346 93.5514L39.7444 93.4546V86.4846V79.5146H60.298H80.8516V72.1574V64.8002H60.298H39.7444L39.7576 60.7343C39.808 45.6931 48.8415 28.7815 62.6248 17.9246Z" />
            <path d="M0.188477 143.306C0.188477 121.741 0.376162 120.698 4.7436 117.992C7.31707 116.397 11.9963 116.338 14.9506 117.863C19.1435 120.028 19.3723 121.321 19.8113 145.342C20.2402 168.835 20.7925 173.314 24.7248 185.207C29.6957 200.241 38.2731 213.636 50.3105 225.165C67.6461 241.769 87.4458 250.459 111.781 252.148C117.07 252.515 121.606 253.227 122.639 253.852C125.704 255.706 127.388 260.069 127.388 266.152C127.388 272.698 126.014 273.036 141.737 262.619C182.525 235.594 206.211 205.946 213.171 173.203C214.064 168.999 214.511 161.167 214.803 144.597L215.205 121.781L217.755 119.234C219.907 117.086 220.869 116.688 223.912 116.688C228.443 116.688 231.709 118.342 233.32 121.454C234.416 123.57 234.524 126.757 234.192 147.191C233.77 173.164 233.131 178.172 228.495 191.809C221.472 212.471 207.587 233.028 188.493 251.033C180.338 258.721 174.981 262.989 162.678 271.597C126.501 296.908 118.64 301.779 113.958 301.78C108.276 301.781 107.232 298.882 107.227 283.089L107.222 271.755L102.762 271.234C89.9006 269.731 71.7995 263.78 60.481 257.333C45.4692 248.783 31.3578 236.23 22.021 223.121C15.6835 214.222 7.86543 198.245 4.90184 188.133C1.22236 175.577 0.188477 165.742 0.188477 143.306Z" />
          </svg>
        </header>

        <div className="login-form">
          <div className="login-form__field">
            <label className="login-form__label" htmlFor="username">
              Email:
            </label>
            <input className="login-form__input" id="username" type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </div>
          <div className="login-form__field">
            <label className="login-form__label" htmlFor="password">
              Senha:
            </label>
            <input className="login-form__input" id="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>
          <button className="login-form__button" onClick={handleLogin}>
            Entrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
